export const CheckIcon = ()=>{
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="35"
        fill="none"
        viewBox="0 0 34 35"
      >
        <path
          stroke="#35424A"
          strokeLinecap="round"
          strokeWidth="4.22"
          d="M28.254 10.204L13.526 24.932a1.055 1.055 0 01-1.492 0l-6.288-6.287"
        ></path>
      </svg>
    )
}