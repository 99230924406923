/*
    General Helper Class
*/

// example: https://bithacker.dev/javascript-object-multi-property-sort

import StorageService from "services/StorageService";

import LOCALIZATION from "services/LocalizationService";
import { format } from "date-fns";
import { DATE_FORMAT } from "constants/DateFormat";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export const isSuperAdmin = () => {
  const token = StorageService.instance.getAccessToken();
  const isSuperAdmin =
    token && parseJwt(token)?.user?.user_role === "Super Administrator";
  return isSuperAdmin;
};
export const isRosterTeam = () => {
  const token = StorageService?.instance?.getAccessToken();
  const isRosterTeam =
    token && parseJwt(token)?.user?.user_role === "Roster Team";
  return isRosterTeam;
};
export const isProjectTeam = () => {
  const token = StorageService.instance.getAccessToken();
  const isProjectTeam =
    token && parseJwt(token)?.user?.user_role === "Project Team";
  return isProjectTeam;
};

export const getToken = () => {
  const token = StorageService.instance.getAccessToken();
  return token;
};

export const getName = () => {
  const token = StorageService.instance.getAccessToken() || {};
  const { first_name, last_name } = parseJwt(token)?.user || {};

  if (!!first_name && !!last_name) {
    return `${first_name} ${last_name}`;
  } else if (!!first_name) {
    return first_name;
  } else {
    return last_name || LOCALIZATION.USER;
  }
};

export const getUserInfo = () => {
  const token = StorageService.instance.getAccessToken() || {};
  const user = parseJwt(token)?.user || {};
  return user;
};

export const getUserId = () => {
  const token = StorageService.instance.getAccessToken() || {};
  return parseJwt(token)?.user_id;
};

export function parseGeneralErrorMessage(object) {
  let message = "";
  for (const property in object) {
    message += object[property]?.toString() + ", ";
  }
  return message.substring(0, message.length - 2);
}

export const parseJwt = (token) => {
  var base64Url = token?.split(".")[1];

  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const sortedTableSwitchIcons = (sortOrder) => {

  return !sortOrder ? 
  <span className="show-only-hover">
    <FaArrowUp />
  </span> : sortOrder === "ascend" ? <FaArrowUp />
  : <FaArrowDown />
};

export const disabledFutureDates = (current) => {
  return current && current.valueOf() > Date.now();
};

// Sort an array
export function sortArray(array = [], sortBy) {
  return (
    array &&
    array.sort(function (a, b) {
      let i = 0,
        result = 0;
      while (i < sortBy.length && result === 0) {
        result =
          sortBy[i].direction *
          (a[sortBy[i]?.prop]?.toString() < b[sortBy[i]?.prop]?.toString()
            ? -1
            : a[sortBy[i]?.prop]?.toString() > b[sortBy[i]?.prop]?.toString()
            ? 1
            : 0);
        i++;
      }
      return result;
    })
  );
}

export function truncateString(str, n) {
  if (str?.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

export function camalize(str) {
  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1).toLowerCase();
  }

  return words.join(" ");
}

// Get Int value of given value
export function getIntVal(value) {
  return parseInt(value || 0);
}

// Add Thousand Separator in value
export function thousandSeprator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const toBase64 = async (file) => {
  const action = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
  const base64String = await action;
  let result = base64String?.split(",")?.[1];
  return result;
};

export function getFiltersCount(filterObj = {}) {
  let count = 0;
  filterObj &&
    // eslint-disable-next-line array-callback-return
    Object.keys(filterObj)?.map((key) => {
      const value = filterObj?.[key];
      const isArray = Array.isArray(value);
      if ((!isArray && !!value) || (!!isArray && !!value?.length > 0)) {
        count++;
      }
    });
  return count;
}

export function isObjectEmpty(obj) {
  return (
    !!obj &&
    !!!Object.keys(obj)
      ?.map((key) => !!obj?.[key])
      ?.filter((i) => i !== false)?.length
  );
}

// Format date this 2023-08-31T00:00:00Z to dd mmmm yyyy
export const formatDateTime = (inputDate) => {
  if (!!inputDate) {
    const parsedDate = new Date(inputDate);

    // Format the date using date-fns
    const formattedDate = format(parsedDate, "dd MMM yyyy");

    return formattedDate;
  }

  return ""; // Return an empty string if inputDate is falsy
};

// // Format date this 1/8/2023 to 1 august 2023
// export const formatDate = (inputDate) => {
//   if (!!inputDate) {
//     const [day, month, year] = inputDate.split('/');
//     const parsedDate = new Date(`${year}-${month}-${day}`);

//     // Format the date
//     const formattedDate = format(parsedDate, 'dd MMM yyyy');
//     return formattedDate;
//   }
// }

// Format time to 1/8/2023 to 1 aug
export const formatDatetoDateMonthYear = (inputDate) => {
  if (!!inputDate) {
    const [day, month, year] = inputDate.split("/");
    const parsedDate = new Date(`${year}-${month}-${day}`);
    // Format the date to display as "1 Aug 2023"
    const formattedDate = format(parsedDate, "d MMM yyyy");
    return formattedDate;
  }
};

// Format time to 1/8/2023 to 1 aug
export const formatDatetoDateMonth = (inputDate) => {
  if (!!inputDate) {
    const [day, month, year] = inputDate.split("/");
    const parsedDate = new Date(`${year}-${month}-${day}`);

    // Format the date to display only the first three characters of the month
    const formattedDate = format(parsedDate, "dd MMM yyyy").slice(0, 7);
    return formattedDate;
  }
};
export const dateFormat = (date) => {
  if (!!date) {
    const inputTimestamp = new Date(date);
    const formattedDate = format(inputTimestamp, "dd MMM yyyy");
    return formattedDate;
  }
};

export const formatDate = (
  date = new Date(),
  formate = DATE_FORMAT.DAY_SLASH_MONTH_SLASH_YEAR
) => {
  return format(date, formate);
};

export const ExtractHoursAndMinutes = (datetime) => {
  const dateObj = new Date(datetime);
  const hours = dateObj.getHours().toString().padStart(2, "0"); // Get hours and pad with
  const minutes = dateObj.getMinutes().toString().padStart(2, "0"); // Get minutes and pad with
  return `${hours}:${minutes}`;
};

export const extractTime = (isoString) => {
  const match = isoString.match(/T(\d{2}:\d{2})/);
  return match ? match[1] : '';
};


export const currentMonthName = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthIndex = new Date().getMonth();
  const currentMonthName = monthNames[currentMonthIndex];
  return currentMonthName;
};
 
/** get initial letters */
// export const getInitials = (fullName) => {
//   const parts = fullName.split(" ");
//   const initials = parts.reduce(
//     (acc, name) => acc + name.charAt(0).toUpperCase(),
//     ""
//   );
//   return initials;
// };

/** get only first two initial letters */
export const getInitials = (fullName) => {
  const parts = fullName.split(" ");
  const initials = parts
    .slice(0, 2)
    .map((name) => name.charAt(0).toUpperCase())
    .join("");
  return initials;
};
 