import React, { useEffect, useState } from "react";

// Antd
import { Button, Steps, Modal, Form, Space, Spin } from "antd";

// Constant

// Style
import "./style.scss";

// Component
import File from "./File";
import Roles from "./Roles";
import Detail from "./Detail";
import Loading from "components/Loading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import Compliance from "./Compliance";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Helper
import { errorNotification } from "helpers/Notification";

// Data parser
import { getUserInfo, parseGeneralErrorMessage } from "helpers/GeneralHelper";

// Date Fns
import { format } from "date-fns";

// Constants
import { API_URLS } from "../../../../src/constants/ApiUrl";
import { REDUX_STATES } from "constants/ReduxStates";
import { DATE_FORMAT } from "constants/DateFormat";
import STATUS_CODES from "constants/StatusCodes";

// Actions
import {
  patchAction,
  postAction,
  getAction,
  triggerProjectLoad,
  deleteAction,
  resetCustomStateData,
} from "store/actions/CRUDAction";
import { ADD_PROJECT_KEYS } from "./Constant";
import { parseEditUserData } from "dataParser/Project";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { CheckIcon } from "./images";
import FileUploading from "./FileUploading";

const {
  FILE_UPLOAD,
  PROJECT_DETAIL,
  LOADING,
  USERS,
  RESPONSE,
  ROLES,
  UPDATE_COMPLIANCE,
  COMPLIANCE,
} = REDUX_STATES;

let projectIdCopy = 0;
let loadingModalCopy = false;
var maxStep = 0;
var stepHistory = {};

const Index = ({ setProjectModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isProjectCompleted, setIsProjectCompleted] = useState(false);
  const [statusCreated, setStatusCreated] = useState(false);
  const [formData, setFormData] = useState({}); // State to store form data
  const [excelFile, setExcelFile] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [ComplianceObject, setComplianceObject] = useState([]);
  const [ComplianceData, setComplianceData] = useState([]);
  const [headerCheckedStates, setHeaderCheckedStates] = useState({});
  const [childCheckedStates, setChildCheckedStates] = useState([]);
  const [form] = Form.useForm();

  const [fileUploading, setFileUploading] = useState(false);
  const [progress, onUploadProgress] = useState("");
  const [uploadFailed, setFailed] = useState("");

  const [defaultActiveComp, setDefaultActiveComp] = useState("");
  const [defaultActiveCompIdx, setDefaultActiveCompIdx] = useState("");
  const [unCheckedCompliance, setUnCheckedCompliance] = useState("");
  const userInfo = getUserInfo();


  const {
    [FILE_UPLOAD + LOADING]: loading = false,
    [FILE_UPLOAD + RESPONSE]: fileData = {},
    [COMPLIANCE + LOADING]: loadingCompliance = false,
    [USERS + LOADING]: loadingUsers = false,
    [USERS + RESPONSE]: allUser = {},
  } = useSelector((state) => state.Crud);

  const getAllUsers = () => {
    dispatch(getAction(API_URLS.USERS, {}, USERS));
  };

  const ComplianceApi = (id) => {
    dispatch(getAction(API_URLS.COMPLIANCE + id, {}, COMPLIANCE)).then(
      (e) => {
        setComplianceData(e);
        setUnCheckedCompliance(e?.success?.data);
      },
      (e) => {
        const errMsg = parseGeneralErrorMessage(
          e.response.data || e.response.data?.detail
        );
        const message =
          e?.response?.status === STATUS_CODES.BAD_REQUEST ||
            STATUS_CODES.FORBIDDEN
            ? errMsg || LOCALIZATION.API_ERROR
            : LOCALIZATION.INTERNAL_SERVER_ERROR_MESSAGE;

        errorNotification(message);
      }
    );
  };

  const next = () => {
    form
      .validateFields()
      .then(() => {
        const currentFormData = form.getFieldsValue();
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...currentFormData,
        }));
        setCurrent(current + 1);
        maxStep = current + 1;
        if (stepHistory[0]) return;
        excelFileUpload();

        stepHistory[current] = true;
      })
      .catch((errorInfo) => {
        stepHistory[current] = false;
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const removeFileData = () => {
    dispatch(resetCustomStateData(FILE_UPLOAD, {}))
  }
  useEffect(() => {
    return (() => {
      setExcelFile([]);
      setFormData({});
      removeFileData();
      setFailed(true);
      form.resetFields();
      handleDeleteUnCompletedProject(projectIdCopy, loadingModalCopy);
      stepHistory[0] = false;
      maxStep = 0;
    })
  }, [])

  const steps = [
    {
      title: LOCALIZATION.CREATE_NEW_PROJECT,
      content: (
        <File
          excelFile={excelFile}
          setExcelFile={setExcelFile}
          confirmOnDelete={!uploadFailed}
          onRemove={() => {
            handleDeleteUnCompletedProject(projectIdCopy, loadingModalCopy);
            setFormData({});
            stepHistory[0] = false;
            maxStep = 0;
          }}
        />
      ),
    },
    {
      title: LOCALIZATION.CONFIRM_DETAIL,
      content: fileUploading ? (
        <FileUploading
          file={excelFile[0]}
          failed={uploadFailed}
          meta={progress}
        />
      ) : (
        <Detail form={form} />
      ),
    },
    {
      title: LOCALIZATION.CONFIRM_COMPLAINCE,
      content: (
        <Compliance
          projectId={projectId}
          ComplianceData={ComplianceData}
          setComplianceData={setComplianceData}
          setComplianceObject={setComplianceObject}
          childCheckedStates={childCheckedStates}
          setChildCheckedStates={setChildCheckedStates}
          headerCheckedStates={headerCheckedStates}
          setHeaderCheckedStates={setHeaderCheckedStates}
          defaultActiveComp={defaultActiveComp}
          defaultActiveCompIdx={defaultActiveCompIdx}
          setDefaultActiveComp={setDefaultActiveComp}
          setDefaultActiveCompIdx={setDefaultActiveCompIdx}
          unCheckedItems={unCheckedCompliance}
          setUnCheckedCompliance={setUnCheckedCompliance}
        />
      ),
    },
    {
      title: LOCALIZATION.MANAGEMENT_TEAM,
      content: <Roles allUser={allUser} />,
    },
  ];

  const getFormData = () => {
    var formData = new FormData();
    formData.append(ADD_PROJECT_KEYS.FILE, excelFile[0]?.originFileObj);
    return formData;
  };

  const addProjectRoles = () => {
    const currentFormData = form.getFieldsValue();
    const data = currentFormData?.group_roles.map((item) => ({ ...item, id: userInfo?.id }));
    for (let element of data) {
      element["projects"] = projectId;
    }
    dispatch(postAction(`${API_URLS.ROLES}`, data, {}, ROLES)).then(
      (response) => {

        handleCheckStatus();
      },
      (e) => {
        const message = e?.response?.data?.errors[0]?.non_field_errors
          ? e?.response?.data?.errors[0]?.non_field_errors
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.ROLES_ADDED_ERROR
            : LOCALIZATION.NETWORK_ERROR;
        errorNotification(message);
      }
    );
  };

  const handleCheckStatus = () => {
    // if (!statusCreated) {

    setTimeout(() => {
      dispatch(
        getAction(`${API_URLS.CHECK_STATUS}${projectId}`, {}, ROLES)
      ).then(
        (response) => {

          if (response?.success) {
            maxStep = 0;
            stepHistory[0] = false;
            setExcelFile();
            setComplianceData();
            setHeaderCheckedStates([]);
            setChildCheckedStates([]);
            form.resetFields();
            setCurrent(0);
            dispatch(triggerProjectLoad());
            setStatusCreated(true);
            setIsProjectCompleted(true);
          }
        },
        (e) => {

          errorNotification(e.payload?.message);
        }
      );
    }, 2000);

    // }
  };

  const updateProjectDetail = (id) => {
    const data = {
      [ADD_PROJECT_KEYS.WORK_DIRECTIVE]:
        formData?.[ADD_PROJECT_KEYS.WORK_DIRECTIVE],
      [ADD_PROJECT_KEYS.CLIENT]: formData?.[ADD_PROJECT_KEYS.CLIENT],
      [ADD_PROJECT_KEYS.WORK_ORDER_NUMBER]:
        formData?.[ADD_PROJECT_KEYS.WORK_ORDER_NUMBER],
      [ADD_PROJECT_KEYS.START_DATE]: formData?.[ADD_PROJECT_KEYS.START_DATE],
      [ADD_PROJECT_KEYS.END_DATE]: formData?.[ADD_PROJECT_KEYS.END_DATE],
      [ADD_PROJECT_KEYS.SWING_ROTATION]:
        formData?.[ADD_PROJECT_KEYS.SWING_ROTATION],
      [ADD_PROJECT_KEYS.SHIFT_TYPE]: formData?.[ADD_PROJECT_KEYS.SHIFT_TYPE],
    };
    dispatch(
      patchAction(`${API_URLS.PROJECT}${projectId}/`, data, {}, PROJECT_DETAIL)
    ).then(
      (response) => {
        patchCompliance(ComplianceObject);
      },
      (e) => {
        const message = e?.response?.data?.errors
          ? e?.response?.data?.errors
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.PROJECT_DETAIL_ADDED_ERROR
            : LOCALIZATION.NETWORK_ERROR;
        errorNotification(message);
      }
    );
  };

  const patchCompliance = (data) => {
    const filteredElements = data.filter(
      (item) => item?.notification_trigger === false
    );
    dispatch(
      patchAction(
        API_URLS.UPDATE_COMPLIANCE + projectId + "/",
        filteredElements,
        {},
        UPDATE_COMPLIANCE
      )
    ).then(
      () => {
        addProjectRoles();
      },
      (e) => {
        const errMsg = parseGeneralErrorMessage(e?.response?.data);
        const message =
          e?.response?.status === STATUS_CODES.BAD_REQUEST
            ? errMsg || LOCALIZATION.UPDATE_ERROR
            : LOCALIZATION.INTERNAL_SERVER_ERROR_MESSAGE;
        errorNotification(message);
      }
    );
  };

  const onSubmit = () => {
    setLoadingModal(true);
    Object.keys(formData || {})?.map((key) => {
      if (key?.includes("date")) {
        if (formData[key] === "Invalid Date") {
          formData[key] = null;
        }
        formData[key] = !!formData[key]
          ? format(new Date(formData[key]), DATE_FORMAT.YEAR_MONTH_DAY)
          : null;
      }
    });
    updateProjectDetail();
  };

  const excelFileUpload = () => {
    if (current === 0) {
      setFailed(false);
      setFileUploading(true);
      dispatch(
        postAction(
          API_URLS.PROJECT,
          getFormData(),
          { onUploadProgress },
          FILE_UPLOAD,
          "formdata"
        )
      ).then(
        (response) => {
          getAllUsers();
          ComplianceApi(response?.data?.payload?.project?.id);
          setProjectId(response?.data?.payload?.project?.id);
          setFileUploading(false);
        },
        (e) => {
          setCurrent(0);
          stepHistory[0] = false;
          errorNotification(e?.response?.data?.errors);
          setFileUploading(false);
          setFailed(true);
        }
      );
    }
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const createAnotherProject = () => {
    window.location.reload();
    setExcelFile();
    setComplianceData();
    setHeaderCheckedStates([]);
    setChildCheckedStates([]);
    form.resetFields();
    setCurrent(0);
    dispatch(triggerProjectLoad());
    setLoadingModal(false);
    setProjectId(null);
  };
  const handleOpenProject = () => {
    setProjectId(null);
    setExcelFile();
    setComplianceData();
    setHeaderCheckedStates([]);
    setChildCheckedStates([]);
    form.resetFields();
    setCurrent(0);
    dispatch(triggerProjectLoad());
    setLoadingModal(false);
  };

  const LoadingModalFooter = () => {
    return (
      <div className="loadingFooter">
        <button id="createNewButton" onClick={createAnotherProject}>
          Create another project
        </button>

        <Link to={`/projects${projectId}/detail/${projectId}`}>
          <button id="openButton" type="auth" onClick={handleOpenProject}>
            Open project
          </button>
        </Link>
      </div>
    );
  };

  const handleModalCancel = () => {
    window.location.reload();
    setProjectId(null);
    setLoadingModal(false);
  };

  const handleDeleteUnCompletedProject = (id, ismodalOpen) => {
    if (id === null || id === 0 || id == "" || id === undefined || ismodalOpen === true)
      return;

    dispatch(deleteAction(API_URLS.DELETE_UNCOMPLETE_PROJECT + id)).then(
      (response) => {
        setProjectId(null);
      },
      (e) => {
        setCurrent(0);
        errorNotification(e?.response?.data?.errors || "Something went wrong!");
      }
    );
  };

  useEffect(() => {
    projectIdCopy = projectId;
    loadingModalCopy = loadingModal;
  }, [projectId, loadingModal]);

  useEffect(() => {
    return () => {
      handleDeleteUnCompletedProject(projectIdCopy, loadingModalCopy);
    };
  }, []);

  useEffect(() => {
    if (form) {
      const currentFormData = form.getFieldsValue();
      form.setFieldsValue({ ...currentFormData, ...formData });
    }
  }, [current, formData]);

  const isNextDisabled = (current) => {
    if (fileUploading) return true;

    switch (current) {
      case 0:
        return !excelFile?.length;
      case 1:
      case 2:
      case 4:
        return false;
    }
  };

  return (
    <>
      {!fileUploading && (loading || loadingUsers || loadingCompliance) && (
        <Loading />
      )}
      <div className="project-wrapper">
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          fields={parseEditUserData(fileData, ADD_PROJECT_KEYS)}
          className="project-form"
        >
          <Steps
            current={current}
            items={items}
            onChange={(num) => {
              if (num <= maxStep) setCurrent(num);
            }}
          />

          <div className="content-wrapper">{steps[current].content}</div>

          <div className="toggle-buttons">
            <div>
              {current === 0 && null}
              {current > 0 && (
                <Button
                  type="light"
                  onClick={() => prev()}
                  disabled={current === 0 ? true : false}
                >
                  {LOCALIZATION.BACK}
                </Button>
              )}
            </div>
            <div>
              {current === steps.length - 1 && (
                <Button type="auth" htmlType="submit">
                  {LOCALIZATION.CREATE_PROJECT}
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  disabled={isNextDisabled(current)}
                  type="auth"
                  onClick={() => next()}
                >
                  {LOCALIZATION.NEXT}
                </Button>
              )}
            </div>
          </div>
        </Form>
        <Modal
          width={426}
          open={loadingModal}
          onCancel={handleModalCancel}
          footer={isProjectCompleted ? LoadingModalFooter : ""}
        >
          <div className="modal-Wrapper">
            {isProjectCompleted ? (
              <div>
                <Space className="onlyLoader creationModal " size="middle">
                  <p className="loadingHead">Project Created!</p>
                  <div className="successIcon">
                    {/* <CheckOutlined className="check" /> */}
                    <CheckIcon />
                  </div>
                </Space>
              </div>
            ) : (
              <div>
                <Space className="onlyLoader onlyLoad " size="middle">
                  <Spin size="large" />
                </Space>

                <p className="modalText">
                  Setting up project settings and creating roster. This may take
                  a few minutes
                </p>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Index;
