export const REDUX_STATES = {
  DEFAULT_KEY: "listing" + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: "dropdown" + new Date().getTime(),
  DEFAULT_POST_KEY: "post" + new Date().getTime(),
  DEFAULT_PUT_KEY: "put" + new Date().getTime(),
  DEFAULT_PATCH_KEY: "patch" + new Date().getTime(),
  DEFAULT_DELETE_KEY: "delete" + new Date().getTime(),
  DEFAULT_SELECTED_KEY: "selected" + new Date().getTime(),
  SELECTED:"selected",
  LOADING: "_loading",
  ERROR: "_error",
  RESPONSE: "_response",
  FORGOT_PASSWORD: "forgotpassword",
  RESET_PASSWORD: "resetpassword",
  FILE_UPLOAD: "fileupload",
  PROJECT: "project",
  PROJECT_DETAIL: "projectdetail",
  COMPLIANCE: "compliance",
  USERS:"users",
  ROLES:"Roles",
  UPDATE_COMPLIANCE: "updateCompliance",
  NOTIFICATIONS:"notifications",
  PREVIOUS_TASKS: "previousTasks",
  NEXT_TASKS: "nextTasks",
  TASKS_ACTIVITY_LIST: "tasksActivityList",
  PROJECTS_LIST:"projects",
  NEXT_USERS:"next",
  PROJECT_ROSTER:"projectroster",
  PROJECTS_DETAIL:"projectdetail",
  PROJECT_FLIGHT:"projectflight",
  MOBE_REPORT:"mobereport",
  ACCOMODATION:"accomodation",
  UPDATE_PROJECT_DETAIL:"updateprojectdetail",
  PERSONNEL_LIST:"personnellist",
  FLIGHT_LIST:"flightlist",
  ACCOMODATION_LIST:"accomodationlist",
  BOOK_FLIGHT:'bookflight',
  FLIGHT:'flight',
  FLIGHTS:"flights",
  PERSONNEL_DETAIL:"personneldetail",
  LOCATIONS:"locations",
  LOCATION:"location",
  ACCOMODATIONS:"accomodations",
  PERSONNEL:"personnel",
  SWING_UPDATE:'swingupdate',
  DASHBOARD:"dashboard",
  PROJECT_COMPLIANCE:"projectcompliance",
  EXISTING_ACCOMODATION:"existingaccomodation",
  PERSONNEL_ACCOMODATION:"personnelaccomodation",
  UPDATE_ACCOMODATION:"updateaccomodation",
  CREATE_ACCOMODATION:"createaccomodation",
  DELETE_ACCOMODATION:"deleteaccomodation",
  UPDATE_BOOKING:"updatebooking",
  LIST_ARIPOTS:"listairpots",
  TASK_LIST:"tasklist",
  UPDATE_TASK:"updatetask",
  ROLES_GROUP:"rolesgroup",
  PERSONNEL_FLIGHT:"personnelflight",
  DELETE_BOOKING:"deletebooking",
  CREATE_BOOKING:"createbooking",
  BVA_REPORT:"bvareport",
  CHANGE_REQUEST:"changerequest",
  PROJECTS_DETAIL_IFS:"projectdetailifs",
  TASK:"task",
  PROJECT_TASK_OVERVIEW:"projecttaskoverview",
  SINGLE_PROJECT:'singleproject'
};
