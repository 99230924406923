import React from 'react'

// Antd
import { Form } from "antd"

// Component
import ComplianceModal from "./ComplianceModal"



const Layout = ({ projectId, ComplianceData, setComplianceData, setComplianceObject, childCheckedStates, setChildCheckedStates, headerCheckedStates, setHeaderCheckedStates, ...rest }) => {
    const [form] = Form.useForm();



    return (
        <Form form={form}>
            <ComplianceModal complianceData={ComplianceData} setComplianceObject={setComplianceObject} setComplianceData={setComplianceData} childCheckedStates={childCheckedStates} setChildCheckedStates={setChildCheckedStates} headerCheckedStates={headerCheckedStates} setHeaderCheckedStates={setHeaderCheckedStates} {...rest} />
        </Form>
    )
}

export default Layout