import React, { useCallback, useMemo } from 'react';

// Ant Desisgn
import { Checkbox, Col, Row, Card } from 'antd';
import { MinusSquareFilled, RightOutlined } from '@ant-design/icons';

//Style
import '../style.scss'
import LocalizationService from 'services/LocalizationService';

const filterUncheck = i => !i.notification_trigger

const Layout = ({
    complianceData,
    setComplianceObject,
    childCheckedStates,
    headerCheckedStates,
    setHeaderCheckedStates,
    defaultActiveComp,
    defaultActiveCompIdx,
    setDefaultActiveComp,
    setDefaultActiveCompIdx,
    unCheckedItems,
    setUnCheckedCompliance
}) => {

    const data = complianceData?.success?.data;

    const activeItems = useMemo(() => defaultActiveComp || Object.entries(data)[0][1] || [], [defaultActiveComp])
    const activeIndex = useMemo(() => defaultActiveCompIdx || 0, [defaultActiveCompIdx])


    const handleHeaderCheckboxChange = (panelIndex, panelName, e, id) => {

        const isChecked = e.target.checked;
        setHeaderCheckedStates(prevState => ({
            ...prevState,
            [panelIndex]: isChecked
        }));


        id?.map((item1, index) => {
            const existingIndex = childCheckedStates.findIndex((item) => item.compliance === item1?.id)


            if (existingIndex !== -1) {
                // If item with the same id exists, update it
                childCheckedStates[existingIndex] = {
                    compliance: item1?.id,
                    notification_trigger: isChecked,
                };
            } else {
                // If item with the same id doesn't exist, add a new item
                childCheckedStates.push({
                    compliance: item1?.id,
                    notification_trigger: isChecked,
                });
            }

        });

        setComplianceObject(childCheckedStates)

        setUnCheckedCompliance((prevItems) => {
            const updatedItems = Object.fromEntries(
                Object.entries(prevItems || {}).map(([key, elements]) => [
                    key,
                    elements.map((item) => {
                        if (key === panelName) {
                            return { ...item, notification_trigger: isChecked };
                        }
                        return item;
                    }),
                ])
            );
            return updatedItems;
        });

    };

    const handleCheckboxChange = (itemId, e) => {
        const isChecked = e.target.checked;
        setUnCheckedCompliance((prevItems) => {
            const updatedItems = Object.fromEntries(
                Object.entries(prevItems || {}).map(([key, elements]) => [


                    key,
                    elements.map((item) => {
                        if (item?.id === itemId) {
                            return { ...item, notification_trigger: isChecked };
                        }
                        return item;
                    }),

                ])
            );

            return updatedItems;
        });

        const existingIndex = childCheckedStates.findIndex((item) => item.compliance === itemId);


        if (existingIndex !== -1) {
            // If item with the same id exists, update it
            childCheckedStates[existingIndex] = {
                compliance: itemId,
                notification_trigger: isChecked,

            };
        } else {


            // If item with the same id doesn't exist, add a new item
            childCheckedStates.push({
                compliance: itemId,
                notification_trigger: isChecked,
            });
        }

        setComplianceObject(childCheckedStates)
    };

    const childCheckedValue = (items) => {
        if (Object.keys(childCheckedStates).length > 0) {
            const foundItem = childCheckedStates?.find((value) => value?.compliance === items?.id);
            return foundItem?.notification_trigger
        }

    }

    const openPanel = useCallback((items, index) => () => {
        setDefaultActiveComp(items)
        setDefaultActiveCompIdx(index)
    }, [])


    const getMinusCounts = (panelName) => {

        const minus = unCheckedItems[panelName]?.filter(filterUncheck)

        return minus?.length == unCheckedItems[panelName]?.length ? false : minus.length > 0
    }



    return (
        complianceData?.success?.data && (

            <div className="compliance-box">
                <div className="text-medium-gray">
                    {LocalizationService.COMPLIANCE_HEADING}
                </div>
                <Row className="h-full">
                    <Col className="col-section">
                        {Object.entries(data).map(([panelName, items], index) => (
                            <Card key={index} onClick={openPanel(items, index)} className={"check-item " + `${activeIndex === index ? 'active' : ''}`}>
                                <div className="content">
                                    <Checkbox
                                        onChange={(e) => handleHeaderCheckboxChange(index, panelName, e, items)}
                                        checked={unCheckedItems[panelName]?.find((item) => item?.notification_trigger === true) ? true : false}
                                        defaultChecked

                                    >
                                        {panelName}
                                    </Checkbox>
                                    {
                                        getMinusCounts(panelName) &&
                                        <MinusSquareFilled className="minus-icon" />
                                    }

                                    <RightOutlined />
                                </div>
                            </Card>
                        ))}
                    </Col>
                    <Col className="col-section items-list-box">
                        {activeItems.map((item, itemIndex) => (
                            <Row key={item.id}>
                                <Col className="item">
                                    <Checkbox
                                        value={item.name}
                                        onChange={(e) => handleCheckboxChange(item.id, e, activeIndex)}
                                        checked={childCheckedValue(item)}
                                        defaultChecked
                                    >
                                        {item.name}
                                    </Checkbox>
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </div>

        )
    );
};

export default Layout;
