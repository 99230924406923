export const API_URLS = {
  LOGIN: "/accounts/login/",
  FORGOT_PASSWORD: "/accounts/reset_password/",
  RESET_PASSWORD: "/accounts/password_reset_confirm/",
  PROJECT: "/project-management/project/",
  DELETE_UNCOMPLETE_PROJECT: "/project-management/project/status/",
  UPDATE_PROJECT: "/project-management/project-update/",
  COMPLIANCE: "/compliance/project-compliances/",
  USERS: "/project-management/project-users/",
  ROLES: "/project-management/project-group/",
  UPDATE_COMPLIANCE: "/compliance/project-compliances/",
  WEBSOCKET_NOTIFICATIONS: "ws/notifications/",
  NOTIFICATIONS: "/notification/",
  NOTIFICATIONS_READ_ALL: "/notification/read",
  TASKS_ACTIVITY: "/task-management/tasks-activity/",
  TASKS_LIST: "/task-management/tasks/",
  PROJECTS_LIST: "/project-management/projects/",
  PROJECT_OPTIONS: "/project-management/project-list",
  PROJECT_USERS: "/project-management/project-users/",
  PROJECT_GROUP: "/project-management/project-group/",
  PROJECT_ROSTER_LIST: "/project-management/project-details/",
  PROJECT_DETAILS: "/project-management/project-details/",
  PROJECT_DETAIL: "/project-management/project-detail/",
  PROJECT_FLIGHT: "/flight-management/booking-personnel/",
  DELETE_FLIGHT: "/flight-management/booking-personnel/",
  MOBE_REPORT: "/flight-management/mobe-report/",
  LIST_ARIPOTS: "/flight-management/airports/",
  UPDATE_PROJECT_DETAIL: "/project-management/project/",
  UPDATE_BOOKING: "/flight-management/booking/",
  DELETE_BOOKING: "/flight-management/booking/",
  FLIGHTS: "/flight-management/flights/",
  FLIGHT_PROJECT: "/flight-management/project/flight",
  LOCATIONS: "/accomodation-management/locations/",
  LOCATION: "/accomodation-management/location/",
  FLIGHT: "/flight-management/flight/",
  FLIGHT_BOOKING: "/flight-management/bookings/",
  PERSONNEL_LIST: "/personnel/project-personnel-status/",
  ALL_PERSONNEL: "/personnel/project-personnel/",
  PERSONNEL_DETAIL: "/personnel/personnel-detail/",
  ACCOMODATIONS: "/accomodation-management/accommodations/",
  ACCOMODATION: "/accomodation-management/accommodation/",
  PERSONNEL_ACCOMODATION: "/accomodation-management/accommodation/personnel/",
  PERSONNEL_FLIGHT: "/flight-management/flight/personnel/",
  FLIGHT_LIST: "/flight-management/flights/",
  ACCOMODATION_LIST: "/accomodation-management/accommodations/",
  SWING_UPDATE: "/personnel/personnel-swing/",
  SWING_HISTORY: "/personnel/swing-update/",
  PERSONNEL_SWING_UPDATE: "/personnel/swing-update/?",
  PERSONNEL_COMPLIANCE_List: "/compliance/personnel-compliances/",
  PROJECT_COMPLIANCE: "/compliance/project-compliances/",
  UPDATE_TASK: "/task-management/task/",
  TASK_PERSONNEL: "/task-management/personnel",
  UPDATE_TASK_STATUS: "/task-management/task-status/",
  BVA_REPORT: "/project-management/projects-bva-reports/",
  CHANGE_REQUEST: "/task-management/tasks/",
  CHECK_STATUS: "/project-management/project/status/",
};
