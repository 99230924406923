import React from 'react'

import { useHistory } from "react-router-dom";

// Component 
import MainScreen from "../../ForgotPassword"

// icon
import { InfoCircleOutlined } from "@ant-design/icons"
import { IoArrowBack } from "react-icons/io5";

// Component
import IconLogin2 from "../../../../assets/images/Icon-login-2.png"
import { Email } from 'components/Common/FormElements'

// Style
import "./style.scss"

// Antd
import { Button, Col, Form, Row } from 'antd'

// Localization
import LOCALIZATION from 'services/LocalizationService'

// Redux
import { useDispatch, useSelector } from "react-redux";

// Actions
import { postAction } from "store/actions/CRUDAction";

// Constants
import APP_URL from 'constants/ApplicationUrls';
import STATUS_CODES from 'constants/StatusCodes';
import { REDUX_STATES } from 'constants/ReduxStates';
import { API_URLS } from 'constants/ApiUrl';

// Helper
import { errorNotification, successNotification } from "helpers/Notification";


const { FORGOT_PASSWORD, LOADING } = REDUX_STATES

const Layout = () => {
    const history = useHistory();
    const dispatch = useDispatch()

    const {
        [FORGOT_PASSWORD + LOADING]: loading = false
    } = useSelector(state => state.Crud)

    const onSubmit = (data) => {
        dispatch(postAction(API_URLS.FORGOT_PASSWORD, data, {}, FORGOT_PASSWORD)).then(
            () => {
                successNotification(LOCALIZATION.LINK_EMAIL)
                localStorage.setItem("forgotEmail",data?.email)
                history.push(APP_URL.AUTH.LINK);
            },
            (e) => {
                const message = e?.response?.data?.Failed
                    ? e?.response?.data?.Failed
                    : e?.response?.status === STATUS_CODES.NOT_FOUND
                        ? LOCALIZATION.INVALID_EMAIL
                        : LOCALIZATION.NETWORK_ERROR;
                errorNotification(message);
            }
        );
    };

    return (
        <MainScreen loading={loading} >
            <Form layout='vertical' className='forgot-wrapper' onFinish={onSubmit} >
                <Row className='form-wrapper-2'>
                    <Col span={24}>
                        <InfoCircleOutlined />
                    </Col>
                    <Col span={24} >
                        <h2> {LOCALIZATION.FORGOT_PASSWORD} </h2>
                    </Col>
                    <Col span={20} >
                        <p> {LOCALIZATION.FORGOT_TEXT} </p>
                    </Col>
                    <Col span={22} className='ml-5 mr-5' >
                        <Email label={LOCALIZATION.EMAIL} name={"email"} required  />
                    </Col>
                    <Col span={22} className='ml-5 mr-5' >
                        <Button type="auth" className="inline-content" htmlType='submit' >
                            {LOCALIZATION.SUBMIT}
                            <img src={IconLogin2} className="ml-10" height={18} width={18} />
                        </Button>
                    </Col>
                    <Col span={22} className='mt-5 mb-8 form-wrapper-2'  >
                        <Button type='light' onClick={() => history.push(APP_URL.AUTH.LOGIN)} className='inline-content' >
                            <IoArrowBack size={17} className='mr-4' />
                            {LOCALIZATION.BACK_TO_LOGIN}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </MainScreen>
    )
}

export default Layout