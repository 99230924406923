/*
    Notifications Helper
*/

import React from "react";

// Antd
import { notification, Modal } from 'antd';

// Localization
import LOCALIZATION from 'services/LocalizationService';

// Antd Icon
import { CloseOutlined } from "@ant-design/icons"

// Constants
import { NOTIFICATION_DURATION } from 'constants/General';

export function showWarning(message, callNextScreen) {
  Modal.warning({
    title: LOCALIZATION.WARNING,
    content: <div>{
      message?.warnings?.map((val) => {
        return (
          <p>{val}</p>
        )
      })
    }</div>,
    onOk() {
      callNextScreen(message)
    }
  });
}

// Success
export function successNotification(message, duration = NOTIFICATION_DURATION) {
  const key = 'success';
  notification.open({
    closeIcon: <CloseOutlined />,
    key,
    duration,
    description:
      typeof (message) === 'string'
        ?
        message || 'Notification'
        :
        <div>{
          message?.map((val) => {
            return (
              <p>{val}</p>
            )
          })
        }</div>,
    className: 'notification-success'
  });
}

// Error
export function errorNotification(message, duration = NOTIFICATION_DURATION) {
  const key = 'error';
  notification.open({
    closeIcon: <CloseOutlined />,
    type: "error",
    placement: "top",
    key,
    duration,
    description: typeof message === 'string' ? message || 'Notification' : (
      <div>
        {message && (Array.isArray(message) ? message.map((val, index) => (
          <p key={index}>{val}</p>
        )) : (
          Object.keys(message).map((key, index) => (
            <p key={index}>{key}: {message[key]}</p>
          ))
        ))}
      </div>
    ),

  })
}

// Warning
export function warningNotification(message, duration = NOTIFICATION_DURATION) {
  const key = 'warning';
  notification.open({
    closeIcon: <CloseOutlined />,
    key,
    duration,
    description: message || 'Notification',
    className: 'notification-warning'
  });
}

// Info
export function infoNotification(message, duration = NOTIFICATION_DURATION) {
  const key = 'info';
  notification.open({
    closeIcon: <CloseOutlined />,
    key,
    duration,
    description: message || 'Notification',
    className: 'notification-info'
  });
}