/*
  Date Picker
*/

// Antd
import { Form } from "antd";

import DatePicker from "./DatePickerComponent";
import "./style.scss";

// Localization
import LOCALIZATION from "../../../../services/LocalizationService";
import { CalendarIcon } from "assets/icons/icons";

function Picker({
  name,
  label,
  placeholder,
  readOnly,
  defaultValue,
  format,
  onChange,
  disabled,
  required,
  onBlur,
  validator,
  validateTrigger,
  disabledDate,
  notwrapInForm,
  type,
  allowClear,
  mode,
  className = "",
  style,
}) {
  // Rules
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
    // {
    //   pattern: SPACE_VALIDATIOR,
    //   message: LOCALIZATION.SPACE_VALIDATION
    // }
  ];

  // Custom Validations
  !!validator && rules.push(validator);

  const DatePickerInput = (
    <DatePicker
      className={className}
      placeholder={placeholder || label}
      onChange={onChange || null}
      defaultValue={defaultValue}
      disabled={disabled}
      onBlur={onBlur}
      picker={type}
      readOnly={readOnly}
      format={format}
      disabledDate={disabledDate}
      allowClear={allowClear !== null ? allowClear : true}
      mode={mode}
      suffixIcon={<CalendarIcon stroke={disabled ? "#EFEFEF" : "#191D23"} />}
      style={style}
    />
  );

  return !!notwrapInForm ? (
    <>{DatePickerInput}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
    >
      {DatePickerInput}
    </Form.Item>
  );
}

export default Picker;
