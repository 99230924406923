export const SECTIONS={
  TASK_CENTRE:"taskcentre",
  PINNED_ITEMS:"pinneditems",
  ACTIVITY:"activity",
  ROSTER_MANAGEMENT:"rostermanagement",
  PROJECTS:"projects",
  NEW_PROJECT:"newprojects",
  NEWMAN_TANKS:"newmantanks",
  BHP:"bhp",
  PERSONNEL:"personnel",
  FLIGHTS:"flights",
  ACCOMODATION:"accomodation",
  REPORTS_INSIGHTS:"reports&insights",
  MANAGEMENT_REPORTS:"managementreports",
  FINANCIAL_REPORTS:"financialreports",
  AI_INSIGHTS:"aiinsights",
  ADMINISTRATION:"administration",
  APP_SETTING:"appsetting",
  MANAGEMENT:"management",
  DATA_SOURCES:"datasources",
  INTIGRATIONS:"intigrations",
  PROJECTS_DETAIL:"projectsdetail"

}