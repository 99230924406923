export const parseUsersNames = (names) => {
    if (!!names?.success?.data) {
        if (Object.keys(names?.success?.data).length !== 0) {
            const parsedData = [];
            for (let element of names?.success?.data) {
                parsedData.push({ name: element.first_name +" "+ element?.last_name, value: element.id });
            }

            return parsedData;
        }
    }
};

export const parseEditUserData = (projectData, ADD_PROJECT_KEYS) => {
    if (projectData && Object.keys(projectData).length > 0) {
      let parsedData = JSON.parse(JSON.stringify(projectData.data.payload.project));
      Object.keys(parsedData || {})?.map((key) => {
        if (key.includes("date")) {
          parsedData[key] = parsedData[key] ? new Date(parsedData[key]) : null;
        }
        // else if(key.includes("end_date")){
        //   parsedData[key] = parsedData[key] ? new Date(parsedData[key]) : null;
        // }
    })
      if (projectData && Object.keys(projectData).length > 0) {
        const fields = [
          {
            name: ADD_PROJECT_KEYS.WORK_DIRECTIVE,
            value: parsedData?.[ADD_PROJECT_KEYS.WORK_DIRECTIVE],
          },
          {
            name: ADD_PROJECT_KEYS.CLIENT,
            value: parsedData?.[ADD_PROJECT_KEYS.CLIENT],
          },
          {
            name: ADD_PROJECT_KEYS.WORK_ORDER_NUMBER,
            value: parsedData?.[ADD_PROJECT_KEYS.WORK_ORDER_NUMBER],
          },
          {
            name: ADD_PROJECT_KEYS.START_DATE,
            value: parsedData?.[ADD_PROJECT_KEYS.START_DATE],
          },
          {
            name: ADD_PROJECT_KEYS.END_DATE,
            value: parsedData?.[ADD_PROJECT_KEYS.END_DATE],
          },
          {
            name: ADD_PROJECT_KEYS.SWING_ROTATION,
            value: parsedData?.[ADD_PROJECT_KEYS.SWING_ROTATION],
          },
          {
            name: ADD_PROJECT_KEYS.SHIFT_TYPE,
            value: parsedData?.[ADD_PROJECT_KEYS.SHIFT_TYPE],
          },
          {
            name: ADD_PROJECT_KEYS.SHIFT_TYPE,
            value: parsedData?.[ADD_PROJECT_KEYS.SHIFT_TYPE],
          },

        ];
        return fields;
      }
    }
  };
  