import React from 'react';

// Antd
import { Form, Col, Row } from 'antd';

// Icon
import { GoPlus } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";

// component
import { Dropdown, Text } from 'components/Common/FormElements';

// Localization
import LOCALIZATION from "services/LocalizationService";

// Style
import "./style.scss"

// Constant
import { ADD_PROJECT_KEYS, FORM_GROUP_ROLES_NAME, PROJECT_GROUP_OPTIONS } from '../Constant';

// Data parser
import { parseUsersNames } from 'dataParser/Project';


function DynamicForm({allUser}) {

    return (
        < div className='mt-15 ml-10 roles-wrapper ' >

            <Form.List
                name={FORM_GROUP_ROLES_NAME.GROUP_ROLES}
                initialValue={[{ user : null, group: null }]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                            <Row gutter={[20, 16]} >
                                <Col xs={20} md={10} lg={10} xl={10}>
                                    <Dropdown name={[name, ADD_PROJECT_KEYS.USER]} options={parseUsersNames(allUser)} placeholder={"Roles"} required />
                                </Col>
                                <Col xs={20} md={10} lg={10} xl={10}>
                                    <Dropdown name={[name, ADD_PROJECT_KEYS.GROUP]} options={PROJECT_GROUP_OPTIONS} placeholder={"group"} required />
                                </Col>
                                <Col xs={4} md={4} lg={4} xl={4} className='d-flex align-center justify-center' >
                                    {fields.length > 1 && (
                                        <RxCross1 size={22} className='remove-icon'
                                            onClick={() => {
                                                remove(name);
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        ))}
                        {fields.length > 10 ? null :

                            <Col xs={20} md={10} lg={10} xl={10} className='add-button'>
                                <Row onClick={() => { add() }} >
                                    <Col span={12}>
                                        {LOCALIZATION.ADD_NEW}
                                    </Col>
                                    <Col span={12} className='d-flex justify-end align-center'>
                                        <GoPlus size={25} />
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </>
                )}
            </Form.List>
        </div>
    );
}

export default DynamicForm;
