import React from "react";

// antd
import { message, Modal } from "antd";
import { FileOutlined } from "@ant-design/icons";

// Component
import { Dragger } from "../../../Common/FormElements";

// Localization
import LOCALIZATION from "services/LocalizationService";

const Index = ({ excelFile, setExcelFile, confirmOnDelete, onRemove }) => {
  const handlefile = (info) => {
    const status = info.file.status;
    if (status === "success") {
      message.success("File uploaded successfully!");
    } else if (status === "error") {
      message.error("File upload failed!");
    }
    setExcelFile(info.fileList);
  };

  const validateFile = (file, obj, callback) => {
    if (excelFile?.length === 0) {
      callback("Required");
    } else {
      if (!!excelFile) {
        const allowedFileTypes = [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
          "application/vnd.ms-excel", // .xls
          "text/csv", // .csv
        ];

        const isAllowedFileType = allowedFileTypes.includes(
          excelFile[0]?.originFileObj?.type
        );

        if (!isAllowedFileType) {
          callback(
            "You can only upload Excel files (.xlsx, .xls) or CSV files."
          );
        } else {
          callback();
        }
      }
    }
    callback();
  };

  const removeFile = () => {
    if (excelFile[0]?.status === "uploading") return false;
    if (confirmOnDelete) {
      return new Promise((resolve) => {
        Modal.confirm({
          title: LOCALIZATION.DELETE_FILE_TITLE,
          icon: null,
          closable: true,
          closeIcon: <i className="icon-clear"></i>,
          content: LOCALIZATION.DELETE_FILE_MESSAGE,
          okButtonProps: "seconday",
          okText: LOCALIZATION.DELETE_FILE,
          okType: "primary",
          cancelText: LOCALIZATION.KEEP_FILE,
          onOk: () => {
            if (onRemove) onRemove();
            setExcelFile([]);
            resolve();
          },
          onCancel: () => {
            resolve(false);
          },
          className: `meesagesModal`,
        });
      });
    } else {
      setExcelFile([]);
    }
  };

  return (
    <>
      <Dragger
        listType={"picture"}
        name={"file"}
        accept={".xlsx , .xls , .csv"}
        onChange={handlefile}
        maxCount={1}
        fileList={excelFile}
        validator={{ validator: validateFile }}
        onRemove={removeFile}
        onUplaodAction={false}
        hideDragger={excelFile?.length >= 1}
      />
    </>
  );
};

export default Index;
