import React from "react";

// Localization
import LOCALIZATION from "services/LocalizationService";

// Style
import "./style.scss";

// Antd
import { Col, Row } from "antd";

// Component
import {
  DatePicker,
  Dropdown,
  Number,
  Text,
} from "components/Common/FormElements";

// Constant
import {
  ADD_PROJECT_KEYS,
  SHIFT_TYPE_OPTIONS,
  SWING_OPTIONS,
} from "../Constant";
import { DATE_FORMAT } from "constants/DateFormat";

// Date fns
import { parse, isAfter, format } from "date-fns";

const Layout = ({ form }) => {
  const dateValidation = (_, val, callback) => {
    const startDate = form.getFieldValue(ADD_PROJECT_KEYS.START_DATE);
    const endDate = form.getFieldValue(ADD_PROJECT_KEYS.END_DATE);

    if(startDate && !endDate){
      callback();
    }

    if (!!startDate && !!endDate) {
      if (isAfter(endDate, startDate)) {
        callback();
      } else {
        const formattedStartDate = format(startDate, "dd/MM/yyyy");
        const formattedEndDate = format(endDate, "dd/MM/yyyy");
        callback(
          `Validation end date (${formattedEndDate}) should not be before the start date (${formattedStartDate})`
        );
      }
    }
    // callback()
  };

  return (
    <div className="project-detail-wrapper">
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.WORK_DIRECTIVE}</p>
        </Col>
        <Col className="f-field">
          <Text
            placeholder={LOCALIZATION.WORK_DIRECTIVE}
            name={ADD_PROJECT_KEYS.WORK_DIRECTIVE}
            required
          />
        </Col>
      </Row>
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.CLIENT}</p>
        </Col>
        <Col className="f-field">
          <Text
            placeholder={LOCALIZATION.CLIENT}
            name={ADD_PROJECT_KEYS.CLIENT}
            required
          />
        </Col>
      </Row>
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.WORK_ORDER_NUMBER}</p>
        </Col>
        <Col className="f-field">
          <Number
            placeholder={LOCALIZATION.WORK_ORDER_NUMBER}
            name={ADD_PROJECT_KEYS.WORK_ORDER_NUMBER}
            required
          />
        </Col>
      </Row>
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.START_DATE}</p>
        </Col>
        <Col className="f-field">
          <DatePicker
            name={ADD_PROJECT_KEYS.START_DATE}
            required
            format={DATE_FORMAT.DAY_SLASH_MONTH_SLASH_YEAR}
          />
        </Col>
      </Row>
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.END_DATE}</p>
        </Col>
        <Col className="f-field">
          <DatePicker
            name={ADD_PROJECT_KEYS.END_DATE}
            validator={{ validator: dateValidation }}
            format={DATE_FORMAT.DAY_SLASH_MONTH_SLASH_YEAR}
            required
          />
        </Col>
      </Row>
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.SWING_ROTATION}</p>
        </Col>
        <Col className="f-field">
          <Dropdown
            name={ADD_PROJECT_KEYS.SWING_ROTATION}
            options={SWING_OPTIONS}
            required
          />
        </Col>
      </Row>
      <Row className="inline-view">
        <Col className="f-lable">
          <p>{LOCALIZATION.SHIFT_TYPE}</p>
        </Col>
        <Col className="f-field">
          <Dropdown
            name={ADD_PROJECT_KEYS.SHIFT_TYPE}
            options={SHIFT_TYPE_OPTIONS}
            required
          />
        </Col>
      </Row>
    </div>
  );
};

export default Layout;
