import React from "react";

// Icon
import { UploadOutlined } from "@ant-design/icons";

// Antd
import { message, Upload, Form } from "antd";

// Style
import "./style.scss";

// Localization
import LOCALIZATION from "../../../../services/LocalizationService";

const Index = ({
  listType,
  placeholder,
  label,
  notwrapInForm,
  name,
  validateTrigger,
  valuePropName,
  type,
  icon,
  maxCount,
  onChange,
  accept,
  required,
  validator,
  fileList,
  onRemove,
  onUplaodAction,
  hideDragger,
}) => {
  const { Dragger } = Upload;
  const props = {
    // action: 'http://3.107.84.167/api/accounts/logout/ ',
    beforeUpload: (file) => {
      const isExcelFile =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcelFile) {
        message.error(`You can only upload ${type} files!`);
      }
      return onUplaodAction === false ? false : isExcelFile;
    },

    progress: {
      strokeColor: {
        "0%": "#E31837",
        "20%": "#E31837",
        "50%": "#E31837",
        "70%": "#E31837",
        "100%": "#E31837",
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const FileUpload = (
    <div
      className={hideDragger ? "hide-dragger" : ""}
    >
      <Dragger
        listType={listType}
        {...props}
        type={type}
        name={name}
        maxCount={maxCount}
        onChange={onChange}
        accept={accept}
        fileList={fileList}
        onRemove={onRemove}
      >
        <p className="ant-upload-drag-icon">
          {icon ? icon : <UploadOutlined style={{ color: "#ccff00" }} />}
        </p>
        <p className="ant-upload-text">
          Drag and drop or <span>choose your file </span> to upload
        </p>
        <p className="ant-upload-hint">
          Only Allow these Types XLSX , XLS , CSV
        </p>
      </Dragger>
    </div>
  );

  let rules = [{ required: required, message: LOCALIZATION.REQUIRED }];

  !!validator && rules.push(validator);

  return !!notwrapInForm ? (
    <>{FileUpload}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
      valuePropName={valuePropName}
      getValueFromEvent={(e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
      }}
    >
      {FileUpload}
    </Form.Item>
  );
};

export default Index;
