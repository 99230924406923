import React from 'react'

import { useHistory } from "react-router-dom";

// Component 
import MainScreen from "../../ForgotPassword"

// Component
import IconLink from "../../../../assets/images/Icon-link.png"

// Constants
import APP_URL from 'constants/ApplicationUrls';

// Style
import "./style.scss"

// Antd
import { Button, Col, Form } from 'antd'

// Localization
import LOCALIZATION from 'services/LocalizationService'

// Icon
import { IoArrowBack } from "react-icons/io5";

const Layout = () => {
    const history = useHistory();

    return (
        <MainScreen>
            <Col span={24} className='link-wrapper mb-15' >
                <Col span={24} >
                    <img src={IconLink} />
                </Col>
                <Col>
                    <h2> {LOCALIZATION.LINK_SENT} </h2>
                </Col>
                <Col span={24} >
                    <p className='link-text' > {LOCALIZATION.RESET_LINK} </p>
                    <p> {localStorage.getItem("forgotEmail")} </p>
                </Col>

                <Col span={24} className='ml-5 mr-5' >
                    <Button type="auth" className="inline-content" onClick={() => {
                        history.push(APP_URL.AUTH.LOGIN)
                        localStorage.setItem("forgotEmail", "")
                    }} >
                        <IoArrowBack size={17} className='mr-4' />
                        {LOCALIZATION.BACK_TO_LOGIN}
                    </Button>
                </Col>
            </Col>

        </MainScreen>
    )
}

export default Layout