/*
  Application Confirm Password Page
*/

import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Antd
import { Form, Button, Input, Col } from "antd"

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import MainScreen from "../../ForgotPassword";

// Helper
import {
  errorNotification,
  successNotification,
} from "../../../../../src/helpers/Notification";


// Constants
import APP_URL from "constants/ApplicationUrls";
import { API_URLS } from "../../../../../src/constants/ApiUrl";
import { REDUX_STATES } from 'constants/ReduxStates';
import STATUS_CODES from 'constants/StatusCodes';

//ACTIONS
import { patchAction } from "../../../../../src/store/actions/CRUDAction";

// Localization
import LOCALIZATION from "../../../../../src/services/LocalizationService";

import "./style.scss"

const { RESET_PASSWORD, LOADING } = REDUX_STATES

function Layout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  let formRef = React.createRef();

  const {
    [RESET_PASSWORD + LOADING]: loading = false
  } = useSelector(state => state.Crud)

  const { uid, token } = useParams();

  const onSubmit = (data) => {
    data["uid"] = uid;
    data["token"] = token;
    dispatch(
      patchAction(API_URLS.RESET_PASSWORD, data, {}, RESET_PASSWORD)
    ).then(
      (response) => {
        form.resetFields();
        successNotification(LOCALIZATION.RESET_PASSWORD_MESSAGE);
        history.push(APP_URL.AUTH.LOGIN);
      },
      (e) => {
        const message = e?.response?.data?.Failed
          ? e?.response?.data?.Failed
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.RESET_PASSWORD_ERROR
            : LOCALIZATION.NETWORK_ERROR;
        errorNotification(message);
      }
    );
  };

  return (
    <MainScreen loading={loading}>
      <Col span={22} className="reset-wrapper">
        <h2>{LOCALIZATION.NEW_PASSWORD}</h2>

        <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
          <Col span={24} >
            <Form.Item
              className="form-group"
              name="password"
              label={LOCALIZATION.NEW_PASSWORD}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              hasFeedback
            >
              <Input.Password className="form-control" />
            </Form.Item>

            <Form.Item
              className="form-group"
              name="confirm_password"
              label={LOCALIZATION.NEW_PASSWORD_CONFIRM}
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Password not match"));
                  },
                }),
              ]}
            >
              <Input.Password className="form-control" />
            </Form.Item>
            <Form.Item>
              <Button
                type="auth"
                htmlType="submit"
                className="mt-3"
              >
                {LOCALIZATION.SAVE}
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </MainScreen>
  );
}

export default Layout;
