/*
  Application Login Page
*/

import React  from "react";

// Antd
import { Col } from 'antd';

// Localization
import LOCALIZATION from "services/LocalizationService";

// Style
import "./style.scss"

// Component
import Loading from "components/Loading";
import Logo from 'assets/images/Logo.png'

function ForgotPassword({ loading, children}) {

    return (
        <div className="Forgot-Password-wrapper" >
            {loading && <Loading/>}
            <div className="header">
                <h1 className="auth-header-text" > <img src={Logo}/> </h1>
            </div>
            <Col span={24} className="form-wrapper" >
                <Col xs={16} md={10} lg={8} xl={6} className="form" >
                        {children}
                </Col>
            </Col>
        </div>
    );
}

export default ForgotPassword;