/*
  Application Login Page
*/

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import IconLogin1 from "../../../assets/images/Icon-login-1.png"
import IconLogin2 from "../../../assets/images/Icon-login-2.png"
import { Email, Password } from "components/Common/FormElements";
import Roles from "../../../components/Project/Create"
import Loading from "components/Loading";
import Logo from 'assets/images/Logo.png'

// Constants
import APP_URL from 'constants/ApplicationUrls';
import STATUS_CODES from 'constants/StatusCodes';
import { API_URLS } from "constants/ApiUrl";

// Actions
import { login } from "store/actions/AuthAction";

// Antd
import { Form, Button, Row, Col } from 'antd';

// Localization
import LOCALIZATION from "services/LocalizationService";

// Helper
import { errorNotification, successNotification } from "helpers/Notification";
import { isProjectTeam, isRosterTeam, isSuperAdmin } from "helpers/GeneralHelper";


// Style
import "./style.scss"

// Action
import { getAction } from "store/actions/CRUDAction";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectModal, setProjectModal] = useState(false)

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);

  const onSubmit = (data) => {
    dispatch(login(API_URLS.LOGIN, data)).then(
      () => {
        if (!!isSuperAdmin()) {
          successNotification(LOCALIZATION.LOG_IN_SUCCESSFULLY)
          history.push(APP_URL.TASK_CENTER)
        }
        else if (!!isRosterTeam()) {
          successNotification(LOCALIZATION.LOG_IN_SUCCESSFULLY)
          history.push(APP_URL.TASK_CENTER)
        }
        else if (!!isProjectTeam()) {
          dispatch(getAction(API_URLS.PROJECTS_LIST, {}, "projectsdetail")).then(
            (response) => {
              successNotification(LOCALIZATION.LOG_IN_SUCCESSFULLY)
              history.push(APP_URL.PROJECT_DETAIL.replace(":projectid", response.results[0]?.id).replace(":id", response.results[0]?.id),)
            }
          )
        }
        else{
          errorNotification(LOCALIZATION.ASSIGN_ROLE_ERROR)
        }
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.detail
          : e?.response?.status === STATUS_CODES.NOT_FOUND
            ? LOCALIZATION.INVALID_EMAIL_OR_PASSWORD
            : LOCALIZATION.NETWORK_ERROR;
        errorNotification(message);
      }
    );
  };

  return (
    <div className="login-wrapper" >
      {loading && <Loading />}
      <div className="header">
      </div>
      <Col xs={12} md={8} lg={6} xl={4} className="title" >
        <h1 className="shadow-text auth-header-text" ><img src={Logo}/> </h1>
      </Col>
      <Col span={24} className="form-row" >
        <Col xs={20} md={12} lg={10} xl={8} className="login-form-wrapper" >
          <h2>{LOCALIZATION.LOGIN}</h2>
          <Form layout="vertical" className="ml-10" onFinish={onSubmit}>
            <Col span={24} >
              <Email label={LOCALIZATION.EMAIL} name={"email"} required />
            </Col>
            <Col span={24} >
              <Password label={LOCALIZATION.PASSWORD} name={"password"} required />
            </Col>
            <Col span={24} className="d-flex justify-center" >
              <a onClick={() => history.push(APP_URL.AUTH.FORGOT_PASSWORD)} > {LOCALIZATION.FORGOT_PASSWORD}? </a>
            </Col>
            <Col span={24} className="mt-3" >
              <Button type="auth" className="inline-content" htmlType="submit" >
                <img src={IconLogin1} className="mr-10" height={14} width={14} />
                {LOCALIZATION.LOGIN}
                <img src={IconLogin2} className="ml-10" height={18} width={18} />
              </Button>
            </Col>
          </Form>
        </Col>
      </Col>
    </div>
  );
}

export default Login;




