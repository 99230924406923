import { FileOutlined, LoadingOutlined } from "@ant-design/icons";
import { Card, Progress, Spin } from "antd";

function humanFileSize(size) {
  var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    +(size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
}

const FileUploading = ({ meta = {}, file = {}, failed = true }) => {
  const percent = ((meta?.progress || 0) * 100).toFixed(1);

  return (
    <Card className={"file-uploading " + (failed && " error")}>
      <div className="meta">
        <FileOutlined className="file-icon" style={{ color: "#ccff00" }} />
        <div>
          <div className="title">{file.name}</div>
          <div className="desc">{humanFileSize(meta.total || 0)}</div>
        </div>
        {Number(percent) >= 100 && (
          <Spin
            className="cancel"
            indicator={<LoadingOutlined />}
            style={{ fontSize: 35, color: "#ccff00" }}
            spin
          />
        )}
      </div>
      <div>
        {Number(percent) < 100 && (
          <Progress percent={percent} status="active" strokeColor={"#ccff00"} />
        )}
      </div>
    </Card>
  );
};

export default FileUploading;
