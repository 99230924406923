

export const ADD_PROJECT_KEYS = {
    WORK_DIRECTIVE: "work_directive",
    CLIENT: "client",
    WORK_ORDER_NUMBER: "work_order_number",
    START_DATE:"start_date",
    END_DATE:"end_date",
    SWING_ROTATION: "swing",
    SHIFT_TYPE: "shift_type",
    FILE:"file",
    GROUP:"group",
    USER:"user"
}
 
export const FORM_GROUP_ROLES_NAME={
    GROUP_ROLES:"group_roles"
}
export const SHIFT_TYPE_OPTIONS = [
    { name: "Day", value: "Day" },
    { name: "Night", value: "Night" },
]

export const SWING_OPTIONS = [
    { name: "1:1", value: "1:1" },
    { name: "2:1", value: "2:1" },
    { name: "4:2", value: "4:2" },
    { name: "8:6", value: "8:6" },
]

const PROJECT_GROUP_KEYS={
    ROSTER_TEAM:'Roster Team',
    PROJECT_TEAM:'Project Team'
}

export const PROJECT_GROUP_OPTIONS = [
    { name: PROJECT_GROUP_KEYS.ROSTER_TEAM, value: "roster_team" },
    { name: PROJECT_GROUP_KEYS.PROJECT_TEAM, value: "project_team" },
]






