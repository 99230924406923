/** external deps */
import React, { useEffect, useState } from "react";
import { Select, Form, Spin, Empty, Tooltip, Checkbox } from "antd";

/** internal deps */
import { getInitials } from "helpers/GeneralHelper";
import { IconDropdown } from "assets/icons/icons";
import LOCALIZATION from "../../../../services/LocalizationService";

import "./style.scss";

const { Option } = Select;

function Dropdown({
  name,
  label,
  showSearch,
  placeholder,
  options,
  onChange,
  defaultValue,
  allowClear,
  disabled,
  required,
  dropdownWidth,
  loading,
  onKeyUp,
  onKeyDown,
  readOnly,
  validator,
  validateTrigger,
  notwrapInForm,
  mode,
  value,
  onClick,
  className,
  onDeselect,
  onClear,
  onPressEnter,
  selectedUser,
  prefix,
  style,
  selectClassName,
  onSearch,
  onselect,
  selectedOption,
  projects,
  selecteddata
}) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const sortedOption = options;

  /**  Validations rules */
  let rules = [{ required: required, message: LOCALIZATION.REQUIRED }];
  if (!!validator) {
    rules.push({ validator: validator });
  }

  const handleChange = (props) => {
    const user = options?.find((item) => item?.value === props);
    setSelectedValue(user);
    if (onChange) {
      onChange(props);
    }
  };
  const DropdownList = (
    <Select
      // defaultOpen={true}
      className={`custom-select ${selectClassName}`}
      // getPopupContainer={trigger => trigger.parentNode}
      mode={mode}
      showSearch={showSearch === false ? false : true}
      allowClear={allowClear === false ? false : true}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder || LOCALIZATION.PLEASE_SELECT}
      onChange={handleChange}
      onClear={onClear}
      onKeyUp={onKeyUp}
      onSearch={onSearch}
      onSelect={onselect}
      onKeyDown={onKeyDown}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
      optionFilterProp="title"
      onPressEnter={onPressEnter}
      onDeselect={onDeselect}
      suffixIcon={<IconDropdown className={""} />}
      showArrow={!disabled}
      readOnly={readOnly}
      style={{ width: dropdownWidth, ...style }}
      maxTagCount="responsive"
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip
          placement="bottomRight"
          title={
            <div>
              {omittedValues.map(({ label }) =>(
                <div>{label}</div>
              ))}
            </div>
          }
        > 
          {<span>{(selectedValue && selectedValue?.length>0 ?`+${selectedValue?.length-1}`:"" || projects?.length>0 ?`+${projects?.length-1}`:"" || omittedValues && omittedValues?.length>0 ?`+${omittedValues?.length}`:"" 
          )}</span>}
        </Tooltip>
      )}
      notFoundContent={
        loading ? (
          <div style={{ textAlign: "center" }}>
            <Spin size="small" />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    >
      {sortedOption &&
        sortedOption.map((data, index) => {
          return (
            <>
              <Option
                disabled={data?.disabled}
                key={index}
                title={data.name}
                value={data.value}
              >
                {data.icon} {data.name}
              </Option>
            </>
          );
        })}

      {selectedOption?.length &&
        selectedOption?.map((item, index) => {
          return (
            <Option key={index} title={item.name} value={item.value}>
              {item.icon} {item.name}
            </Option>
          );
        })}
    </Select>
  );

  useEffect(() => {
    setSelectedValue(defaultValue || []);
  }, [defaultValue]);

  return (
    <div
      className={
        !prefix
          ? "custom-select-prefix-ident"
          : "relative custom-select-component"
      }
    >
      {prefix && (
        <span className="nameCircle">
          {getInitials(selectedValue?.name ?? selectedUser)}
        </span>
      )}
      {notwrapInForm ? (
        <> {DropdownList}</>
      ) : (
        <Form.Item
          name={name}
          className={className}
          label={label}
          validateTrigger={validateTrigger || "onBlur"}
          rules={rules}
        >
          {DropdownList}
        </Form.Item>
      )}
    </div>
  );
}

export default Dropdown;
