import { BASE_URL, BASE_URL_ADMIN } from "config/environment";
import { SECTIONS } from "./MenuSections";

const {
  TASK_CENTRE,
  BHP,
  NEW_PROJECT,
  NEWMAN_TANKS,
  PERSONNEL,
  FINANCIAL_REPORTS,
  MANAGEMENT_REPORTS,
  AI_REPORTS,
  FLIGHTS,
  APP_SETTING,
  MANAGEMENT,
  DATA_SOURCE,
  INTIGRATIONS,
  PINNED_ITEMS,
  ACTIVITY,
  PROJECTS,
  ACCOMODATION,
} = SECTIONS;

const URL = {
  WELCOME: "/welcome", // Menu A
  MENU_A: "/menua", // Menu A
  MENU_B: "/menub", // Menu B
  PROJECT: "/project",

  TASK_CENTER: `/${TASK_CENTRE}/task`,

  TASK_CENTER_FILTER: `/${TASK_CENTRE}/task/:id`,

  PINNED_ITEMS: `/${PINNED_ITEMS}/pinned`,

  ACTIVITY: `/${ACTIVITY}/activity`,

  PROJECTS: `/${PROJECTS}/projects`,

  ADD_PROJECTS: `/${NEW_PROJECT}/add/`, // Add Projects

  NEW_PROJECT: `/${PROJECTS}/projects/new`,

  PROJECT_DETAIL: `/${PROJECTS}:projectid/detail/:id`,

  BHP: `/${BHP}/bhp`,

  PERSONNEL_LIST: `/${PERSONNEL}/list/`,

  FLIGHTS_LIST: `/${FLIGHTS}/list/`,

  ACCOMODATION_LIST: `/${ACCOMODATION}/list`,

  MANAGEMENT_REPORTS: `/${MANAGEMENT_REPORTS}/reports/management`,

  FINANCIAL_REPORTS: `/${FINANCIAL_REPORTS}/reports/financial`,

  AI_REPORTS: `/${AI_REPORTS}/reports/ai`,

  APP_SETTING: `/${APP_SETTING}/setting`,

  MANAGEMENT: `${BASE_URL_ADMIN}smart-roster/admin/`,

  DATA_SOURCE: `/${DATA_SOURCE}/setting/datasource`,

  INTIGRATIONS: `/${DATA_SOURCE}/setting/intigrations`,

  NOTIFICATIONS: "/notifications",

  AUTH: {
    LOGIN: "/login", // Login
    FORGOT_PASSWORD: "/forgotpassword", // Forgot Password
    LINK: "/forgotlink", // Link Screen
    RESET_PASSWORD: "/resetpassword/:uid/:token",
  },
  ERROR: {
    PAGE404: "/page404",
    PAGE500: "/page500",
  },
};

export default URL;
