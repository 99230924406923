import React, { useCallback, useEffect } from "react";

// Antd
import { Form, Button } from "antd";

// Localization
import LOCALIZATION from "../../../../services/LocalizationService";

const ToggleButtons = ({
  name,
  form,
  options,
  disabled,
  setSelectedData,
  selectedData,
}) => {
  let array = selectedData ||  [];
  const onClick = useCallback(
    (value) => () => {
      if (selectedData.includes(value)) {
        setSelectedData(selectedData.filter((i) => i !== value));
        array = selectedData.filter((i) => i !== value);
      } else {
        setSelectedData((prev) => [...prev, value]);
        array = [...selectedData, value];
      }
      form.setFieldValue(name, array.toString());
      form.submit(); 
    },
    [selectedData, form]
  );

  return (
    <div className="border-full-toggle">
      {options
        ? options.map((option, index) => (
            <Button
              disabled={disabled}
              onClick={onClick(option.value)}
              type={selectedData.includes(option.value) ? "primary" : "outline"}
              key={option.value}
              className={
                index < options.length - 1
                  ? "border-right-toggle no-round"
                  : "no-round"
              }
            >
              {option.icon} {option.label}
            </Button>
          ))
        : null}
    </div>
  );
};

function RadioButton({
  name,
  label,
  disabled,
  notwrapInForm,
  options,
  required,
  validator,
  validateTrigger,
  form,
  setSelectedData,
  selectedData,
}) {
  let rules = [{ required: required, message: LOCALIZATION.REQUIRED }];

  // Custom Validations
  !!validator && rules.push(validator);

  return !!notwrapInForm ? (
    <>{ToggleButtons}</>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      validateTrigger={validateTrigger || "onBlur"}
    >
      <ToggleButtons
        form={form}
        name={name}
        options={options}
        disabled={disabled}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
      />
    </Form.Item>
  );
}

export default RadioButton;
